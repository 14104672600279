import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`A great place to ride in the low mountain ranges of Germany? Yes! The Black
Forest has more to offer than you think. Long descents up to almost 1000vm
and a huge density of singletrails right next to the city, make Freiburg a
great place to ride.`}</p>
    <br />
    <p>{`The local mountain bike club is very active in building and maintaining
trails, so they are in good shape. You won’t find much rocks, but grippy
forest floor and roots. The terrain is not steep and the trails are on the
flowy and fast side. With the “Baden to the bone” trail they finally also
have jump trail.`}</p>
    <br />
    <p>{`There can be some weeks with snow, but usually you can ride
the trails the whole year.`}</p>
    <TrailguidePlugin content="lat=47.9749&lng=7.9333&coverage=10" mdxType="TrailguidePlugin" />
    <p className="text-xs mb-16" align="center">
      <p>{`Open these trails directly in the `}<a parentName="p" {...{
          "href": "https://trailguide.net/?pos=12,47.9749,7.9333"
        }}>{`web-app`}</a></p>
    </p>
    <h4>{`Canadian Trail`}</h4>
    <p>{`The second official trail of the Mountainbike Freiburg e.V. and the first
one the canadians built.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/1552"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/freiburg2.jpg" mdxType="Image" />
    <br />
    <h4>{`El Presidente`}</h4>
    <p>{`A long trail with a mix of everything. Lots of roots, technical sections
over rocky terrain and some really high speed parts where you can let it go.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/753"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/freiburg4.jpg" mdxType="Image" />
    <br />
    <h4>{`Hirzberg`}</h4>
    <p>{`Many trails are really close to the city and you can start right from the center.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/1455"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/freiburg5.jpg" mdxType="Image" />
    <br />
    <h4>{`Kybfelsensteig`}</h4>
    <p>{`The trails have not much rocks and you usually ride on roots and forest floor.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/808"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/freiburg3.jpg" mdxType="Image" />
    <br />
    <h4>{`Zastler Steig`}</h4>
    <p>{`Narrow with switchbacks.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/754"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/freiburg1.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      